import { Route, Routes } from '@angular/router';
import { AUDIENCE_SLUGS } from '@routes/routes.types';

export const PIPELINE_SLUGS = {
    table: 'table',
    chooseVariant: 'choose-variant',
    form: 'form',
} as const;

export const PIPELINE_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PIPELINE_SLUGS.table,
    },
    {
        path: PIPELINE_SLUGS.table,
        title: 'Table',
        loadComponent: () =>
            import(
                '@features/audience/pipelines/components/pipelines-tables/pipelines-tables.component'
            ).then(m => m.PipelinesTablesComponent),
    },
    {
        path: PIPELINE_SLUGS.chooseVariant,
        title: 'Choose variant',
        loadComponent: () =>
            import(
                '@features/audience/pipelines/components/pipeline-form/pipeline-choose-variant/pipeline-choose-variant.component'
            ).then(m => m.PipelineChooseVariantComponent),
    },
    {
        path: PIPELINE_SLUGS.form,
        title: 'Form',
        loadComponent: () =>
            import(
                '@features/audience/pipelines/components/pipeline-form/pipeline-form.component'
            ).then(m => m.PipelineFormComponent),
    },
    {
        path: '**',
        redirectTo: PIPELINE_SLUGS.table,
    },
];

export const PIPELINE_ROUTE: Route = {
    path: AUDIENCE_SLUGS.pipelines,
    title: 'Pipelines',
    loadComponent: () =>
        import('@features/audience/pipelines/pipelines.component').then(m => m.PipelinesComponent),
    children: PIPELINE_ROUTES,
};
